export const conflicts = [
    {
        value: "",
        option: "Conflict?"
    },
    {
        value: "Russian Ukraine Invasion, 2021",
        option: "Russian Ukraine Invasion, 2021"
    },
    {
        value: "Gaza Genocide, 2023",
        option: "Gaza Genocide, 2023"
    },
    {
        value: "Hamas Terrorist Attack, 2023",
        option: "Hamas Terrorist Attack, 2023"
    },
    {
        value: "Israeli Invasion of Lebanon, 2024",
        option: "Israeli Invasion of Lebanon, 2024"
    },
]